export default [
  "#BA68C8",
  "#7986CB",
  "#4FC3F7",
  "#4DB6AC",
  "#AED581",
  "#FFD54F",
  "#FF8A65",
  "#A1887F",
  "#90A4AE",
];
